import React, {useState} from 'react'
import {Alert, Button, message, Popover} from 'antd'
import {QuestionCircleFilled} from '@ant-design/icons';

import '../../App.css'
import '../../styles/pageTemplatesStyles.css';
import './authStyles.css'

import AuthBanner from './AuthBanner'

import {Link, useNavigate} from 'react-router-dom'

function SignInForm(props) {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [loading, setLoading] = useState(false)

    const [errorMessageRegister, setErrorMessageRegister] = useState()

    const history = useNavigate();

    const handleNameChange = (evt) => {
        setName(evt.target.value)
    }

    const handleEmailChange = (evt) => {
        setEmail(evt.target.value)
    }

    const handlePasswordChange = (evt) => {
        setPassword(evt.target.value)
    }

    const handleConfirmPasswordChange = (evt) => {
        setConfirmPassword(evt.target.value)
    }


    async function handleSubmit(evt) {
        evt.preventDefault()

        try {
            setLoading(true)
            const response = await fetch(`${process.env.REACT_APP_API_LINK}/v1/users`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + process.env.REACT_APP_LOGIN_TOKEN
                },
                body: JSON.stringify({
                    user: {
                        name,
                        email,
                        password,
                        confirmPassword
                    }
                })
            })
            const json = await response.json()
            if (json.user === undefined) {
                throw json
            }
            localStorage.setItem("token", json.jwt)
            localStorage.setItem("userObj", JSON.stringify(json))
            console.log(json)
            history.push('/')
            history.go(0)
            message.success('Account successfully created!')
        } catch (error) {
            if (error.errors === undefined) {
                console.log("Something went wrong, please try later")
                setErrorMessageRegister('Something went wrong, please try later')
            } else {
                setErrorMessageRegister(error.errors.map((message) =>
                    <span key={message}>{message} <br /></span>
                ))
            }
            localStorage.clear()
        }
        setName("")
        setEmail("")
        setPassword("")
        setConfirmPassword("")
        setLoading(false)
    }


    return (
        <div className='wrapper template-padding'>
            <div className='wrapped-container auth-container'>
                <AuthBanner />
                <div className='auth-inner-container'>
                    <div>
                        {errorMessageRegister && <Alert tpye='error' message={errorMessageRegister} />}
                        <h1>Sign Up</h1>
                        <form className="auth-form" onSubmit={handleSubmit}>
                            <div className="field">
                                <label>Name*</label>
                                <input className='ant-input' value={name} onChange={handleNameChange} type="text" placeholder="name" />
                            </div>
                            <div className="field">
                                <label>Email*</label>
                                <input className='ant-input' value={email} onChange={handleEmailChange} type="email" placeholder="email" />
                            </div>
                            <div className="field">
                                <Popover
                                    content={(
                                        <div className='password-requirements'>
                                            <h6>Password requirements</h6>
                                            <ul>
                                                <li>Password must have at least 8 characters</li>
                                                <li>Password must contain at least one uppercase letter</li>
                                                <li>Password must contain at least one digit</li>
                                            </ul>
                                        </div>
                                    )}
                                >
                                    <label>Password* <QuestionCircleFilled /></label>
                                </Popover>
                                <input className='ant-input' value={password} onChange={handlePasswordChange} type="password" placeholder="password" />
                            </div>
                            <div className="field">
                                <label>Confirm Password*</label>
                                <input className='ant-input' value={confirmPassword} onChange={handleConfirmPasswordChange} type="password" placeholder="confirm password" />
                            </div>
                            <Button
                                type="primary"
                                htmlType='submit'
                                loading={loading}
                                block={true}
                                shape='round'
                                style={{
                                    margin: '24px 0'
                                }}
                            >Submit</Button>
                        </form>
                        <p>Already have an account? Log in <Link to='/login'>here</Link></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignInForm