import React, {useState} from 'react'
import '../../App.css'
import '../../styles/pageTemplatesStyles.css';
import './authStyles.css'
import {Alert, Button, message} from 'antd'

import {useAuth} from '../../contexts/AuthContext'
import AuthBanner from './AuthBanner'

import {Link, useNavigate} from 'react-router-dom';


function LoginForm(props) {

    // const [isModalVisible, setIsModalVisible] = useState(false)

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)

    const [errorMessageLogin, setErrorMessageLogin] = useState(false)

    const { credentials, setAuthed } = useAuth();

    const handleEmailChange = (evt) => {
        setEmail(evt.target.value)
    }

    const handlePasswordChange = (evt) => {
        setPassword(evt.target.value)
    }

    const history = useNavigate();

    async function handleSubmit(evt) {
        evt.preventDefault()

        // setLoading(true)
        try {
            setLoading(true)
            const response = await fetch(`${process.env.REACT_APP_API_LINK}/v1/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify({
                    email,
                    password
                })
            })
            const json = await response.json()
            if (json.user === undefined) {
                throw json
            }
            localStorage.setItem("token", json.jwt)
            localStorage.setItem("userObj", JSON.stringify(json))
            console.log(json);
            // setIsLoginModalVisible(false)
            history.push('/')
            history.go(0)
            message.success(json.success)
        } catch (error) {
            if (error.errors === undefined) {
                console.log("Something went wrong, please try later")
                setErrorMessageLogin('Something went wrong, please try later')
            } else {
                setErrorMessageLogin(error.errors)
                console.log(error.errors)
            }
            localStorage.clear()
            setAuthed(false)
        }
        setEmail("")
        setPassword("")
        setLoading(false)
        // window.location.reload()

    }



    return (
        <div className="wrapper template-padding">
            <div className='wrapped-container auth-container'>
                <AuthBanner />
                <div className='auth-inner-container'>
                    <div>
                        {credentials &&
                            <>
                                <Alert type='error' message='There is already someone logged in this session' showIcon='true' />
                            </>}
                        {!credentials &&
                            <>
                                {errorMessageLogin && <Alert message={errorMessageLogin} type='error' />}
                                <h1>Log In</h1>
                                <form onSubmit={handleSubmit} className='auth-form'>
                                    <div>
                                        <label>Email</label>
                                        <input className='ant-input' value={email} onChange={handleEmailChange} type="email" placeholder="email" />
                                    </div>
                                    <div>
                                        <label>Password</label>
                                        <input className='ant-input' value={password} onChange={handlePasswordChange} type="password" placeholder="password" />
                                    </div>
                                    <Link
                                        to='/dfdfd'
                                        style={{
                                            margin: '8px 0 0 0',
                                            display: 'block'
                                        }}
                                    >Forgot my password</Link>
                                    <Button
                                        type="primary"
                                        htmlType='submit'
                                        loading={loading}
                                        block={true}
                                        shape='round'
                                        style={{
                                            margin: '16px 0 24px 0'
                                        }}
                                    >Log In</Button>
                                </form>
                                <p>Don't have an account yet? Sign up <Link to='/signup'>here</Link></p>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginForm