import React from "react";
import '../App.css';
import '../styles/pageTemplatesStyles.css'

function ProductPage() {

    return (
        <div className='wrapper'>
            <h1>Products</h1>
        </div>
    );
}

export default ProductPage;