import React, {useRef, useState} from "react";
import '../../App.css';
import '../../styles/pageTemplatesStyles.css';
import './postProductsStyles.css';

import {BackTop, Button, Divider, Input, Modal, notification, Pagination, Result, Spin} from 'antd'

import Axios from 'axios';

function PostItems() {

    // handling showing products

    const [keyboards, setKeyboard] = useState([]);
    const [itemsAmount, setitemsAmount] = useState([]);
    const [loading, setLoading] = useState(false);
    const [wrong, setWrong] = useState(false);

    const [searchable, setSearchable] = useState('');

    const submitKeyboard = () => {

        setLoading(true);

        getAliData(current);

    }

    // get the data via a more easily accessible function
    const getAliData = async (current) => {
        try {
            setWrong(false)
            await Axios.get(
                `${process.env.REACT_APP_API_LINK}/v1/affiliate/search?keywords=${searchable}&page_no=${current}`
            ).then((res) => {
                console.log(res);
                setKeyboard(
                    res.data.aliexpress_affiliate_product_query_response.resp_result
                        .result.products.product
                );
                setitemsAmount(
                    res.data.aliexpress_affiliate_product_query_response.resp_result
                        .result
                );
                setLoading(false);
                setWrong(false);
            });
        } catch (err) {
            console.log(err);
            setLoading(false);
            setWrong(true)
        }
    }

    // handling modal

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    // new object will copy of the current selected product 
    const [modalInfo, setModalInfo] = useState({});

    // showing the text override styling
    const modalTextStyle = {
        color: '#181818'
    }

    const modalImageStyle = {
        borderRadius: '8px',
        border: '1px solid #181818',
        marginBottom: '24px',
        marginRight: '24px'
    }


    // reading the input and submitting it

    const imageRef = useRef();
    const idRef = useRef();
    const titleRef = useRef();
    const sellingPriceRef = useRef();
    const amountLeftRef = useRef();
    const afflinkRef = useRef();

    function handleSubmit(e) {
        e.preventDefault();
        setIsModalVisible(false);
        const args = {
            message: 'Upload successfull!',
            description:
                `${titleRef.current.value} for ${sellingPriceRef.current.value} with the ID ${idRef.current.value} got uploaded. Check out the detail in the database. This message will close in a few seconds`,
        };
        notification.success(args);
    }


    // pagination

    const [current, setCurrent] = useState(1);

    const handleChange = value => {
        setCurrent(value)
        console.log(value)
        setLoading(true)
        getAliData(value)
    }

    // back to top

    const backToTopStyle = {
        height: 40,
        width: 40,
        lineHeight: '40px',
        borderRadius: 4,
        backgroundColor: '#1088e9',
        color: '#fff',
        textAlign: 'center',
        fontSize: 14,
    };

    return (
        <div className='wrapper template-padding'>
            <div className='wrapped-container'>
                <h1>Post items to the database</h1>
                <Input.Search placeholder='search for keyboards' allowClear enterButton onChange={e => setSearchable(e.target.value)} onPressEnter={submitKeyboard} onSearch={submitKeyboard} />
                <section className='discover'>
                    {/* testing mapping with API */}
                    {loading && <Spin size='large' tip='Loading keyboards...' />}
                    {!loading && (
                        <>
                            {keyboards.map((keyboard, index) => (
                                <div className='testCard' key={keyboard.product_id}>
                                    <img src={keyboard.product_small_image_urls.string[0]} alt={keyboard.product_title} />
                                    <p>{keyboard.product_title}</p>
                                    <p>{keyboard.discount ? `$${keyboard.app_sale_price} (original price: $${keyboard.original_price} with ${keyboard.discount} discount)` : `$${keyboard.original_price} Original price`}</p>
                                    <Button type='primary' onClick={() => {
                                        const copyArray = [...keyboards];
                                        setModalInfo(copyArray[index])
                                        showModal()
                                    }}>Reveal info</Button>
                                </div>
                            ))}
                            <Pagination
                                defaultCurrent={current}
                                onChange={handleChange}
                                total={itemsAmount.current_record_count}
                            />
                            {isModalVisible && <Modal
                                open={isModalVisible}
                                footer={null}
                                onCancel={handleCancel}
                                title='Product Information'
                                width='50vw'
                            >
                                <h4 style={modalTextStyle}>Edit Product Information:</h4>
                                <div className='images-grid'>
                                    {modalInfo.product_small_image_urls.string.map((image, imageIndex) => {
                                        return (
                                            <img key={imageIndex} style={modalImageStyle} src={image} alt={imageIndex} />
                                        )
                                    })}
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <label>Product ID</label>
                                    <input className='ant-input' type='text' name='product-id' ref={idRef} defaultValue={modalInfo.product_id}></input>
                                    <label>Product Name</label>
                                    <input className='ant-input' type='text' name='product-name' ref={titleRef} defaultValue={modalInfo.product_title}></input>
                                    <label>Selling Price in $ {modalInfo.discount ? `with ${modalInfo.discount} discount (Orginal Price $${modalInfo.original_price}) ` : ''}</label>
                                    <input disabled className='ant-input' type='text' name='sale-price' ref={sellingPriceRef} defaultValue={modalInfo.app_sale_price ? modalInfo.app_sale_price : modalInfo.original_price}></input>
                                    <label>In Stock</label>
                                    <input disabled className='ant-input' type='text' name='in-stock' ref={amountLeftRef} defaultValue={modalInfo.lastest_volume}></input>
                                    <label>Affiliate Link</label>
                                    <input disabled className='ant-input' type='text' name='affiliate-link' ref={afflinkRef} defaultValue={modalInfo.promotion_link}></input>
                                    <label>Upload own images</label>
                                    <input type='file' name='upload-images' ref={imageRef}></input>
                                    <Button className='submit-product' type='primary' htmlType='submit'>Upload to database</Button>
                                </form>
                                <Divider />
                                <h6 style={modalTextStyle}>General Info:</h6>
                                <div className='general-info-container'>
                                    <p style={modalTextStyle}>Relevant Market Comission Rate: {modalInfo.relevant_market_commission_rate}</p>
                                    <p style={modalTextStyle}>Evaluate rate: {modalInfo.evaluate_rate}</p>
                                    <p style={modalTextStyle}>Category: {modalInfo.first_level_category_name}</p>
                                    <p style={modalTextStyle}>Original Shop link: <a href={modalInfo.shop_url}>Visit Link</a></p>
                                    <p style={modalTextStyle}>Product details link: <a href={modalInfo.product_detail_url}>Visit Link</a></p>
                                </div>
                            </Modal>
                            }
                        </>
                    )}
                    {wrong && <Result
                        status='500'
                        title='Can not find the product'
                        subTitle='Try something else'
                    />}
                </section>
                <BackTop>
                    <div style={backToTopStyle}>UP</div>
                </BackTop>
            </div>
        </div>
    );
}

export default PostItems;