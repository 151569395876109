import React from "react";
import './App.css';
import Nav from "./components/Nav";
import Footer from "./components/footer/footer"
import { AuthProvider } from "./contexts/AuthContext";
import { ConfigProvider } from "antd";

function App() {
  return (
    <AuthProvider>
        <ConfigProvider theme={{
            token: {
                colorPrimary: '#f75578',
                bodyBackground: '#0f0f0f',
                textColor: '#FFFFFF',
                inputColor: '#181818',
                fontFamily: 'Avenir Next, serif',
                modalHeadingColor: '#181818',
                alertMessageColor: '#181818',
                btnDefaultColor: '#2d62ed',
            },
            components: {
                Button: {
                    colorPrimary: '#f75578',
                    colorBgContainer:'#2d62ed',
                    colorBorderSecondary: 'transparent',
                    colorPrimaryBorderHover:'transparent'
                }
            }
        }}>
            <div>
                <Nav />
                <Footer />
            </div>
        </ConfigProvider>
    </AuthProvider>
  );
}

export default App;