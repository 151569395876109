import React from "react";
import '../../App.css';
import './builderTypeStyles.css'
import AddComponent from './components/add-component/AddComponent'

import {useRecoilValue} from 'recoil'
import {
    preCableAtom,
    preDeskmatsAtom,
    preKeycapsAtom,
    preLubricationAtom,
    preOringsAtom,
    prePreBuiltAtom,
    preStabilizersAtom,
    preSwitchFilmsAtom,
    preWristRestsAtom
} from '../../recoil/atoms/builderPre'


function PreBuiltBuilder() {

    // const [builderTypeAtomRead, setBuilderTypeAtomRead] = useRecoilState(initBuilderAtom)

    // useEffect(() => {
    //     setBuilderTypeAtomRead('pre')
    // }, [])


    const readPreBuilt = useRecoilValue(prePreBuiltAtom)
    const readPreKeycaps = useRecoilValue(preKeycapsAtom)
    const readPreLubrication = useRecoilValue(preLubricationAtom)
    const readPreStabilizers = useRecoilValue(preStabilizersAtom)
    const readPreOrings = useRecoilValue(preOringsAtom)
    const readPreSwitchFilms = useRecoilValue(preSwitchFilmsAtom)
    const readPreCable = useRecoilValue(preCableAtom)
    const readPreDeskmats = useRecoilValue(preDeskmatsAtom)
    const readPreWristRests = useRecoilValue(preWristRestsAtom)



    return (
        <div className='builder-type'>
            <div className='builder-type-container main-components'>
                <table
                    style={{
                        width: "100%",
                        borderSpacing: 0,
                    }}
                >
                    <thead>
                        <tr>
                            <th
                                style={{
                                    paddingLeft: "24px",
                                    maxWidth: "60px",
                                }}
                            >
                                Main Components
                            </th>
                            <th></th>
                            <th></th>
                            {readPreBuilt.name === "" ? (
                                <>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </>
                            ) : (
                                <>
                                    <th>Price</th>
                                    <th>Discount</th>
                                    <th>Total</th>
                                </>
                            )}
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className="component-row-builder">
                        {readPreBuilt.name === "" ? (
                            <AddComponent componentType="Pre-built" componentFilled={false} />
                        ) : (
                            <AddComponent
                                componentType="Pre-built"
                                componentFilled={true}
                                title={readPreBuilt.name}
                                price={readPreBuilt.price}
                                originalPrice={readPreBuilt.original_price}
                                discount={readPreBuilt.discount}
                                img={readPreBuilt.img}
                                buyLink={readPreBuilt.buyLink}
                            // details={readPreBuilt.details}
                            // description={readPreBuilt.description}
                            />
                        )}
                    </tbody>
                    <thead className="component-header-extra">
                        <tr>
                            <th
                                style={{
                                    paddingLeft: "24px",
                                    maxWidth: "60px",
                                }}
                            >
                                Accessoires (optional)
                            </th>
                        </tr>
                    </thead>
                    <tbody className="component-row-builder">
                        {readPreKeycaps.name === "" ? (
                            <AddComponent componentType="Keycaps" componentFilled={false} />
                        ) : (
                            <AddComponent
                                componentType="Keycaps"
                                componentFilled={true}
                                title={readPreKeycaps.name}
                                price={readPreKeycaps.price}
                                originalPrice={readPreKeycaps.original_price}
                                discount={readPreKeycaps.discount}
                                img={readPreKeycaps.img}
                                buyLink={readPreKeycaps.buyLink}
                                details={readPreKeycaps.details}
                                description={readPreKeycaps.description}
                            />
                        )}
                    </tbody>
                    <tbody className="component-row-builder">
                        {readPreLubrication.name === "" ? (
                            <AddComponent componentType="Lubrication" componentFilled={false} />
                        ) : (
                            <AddComponent
                                componentType="Lubrication"
                                componentFilled={true}
                                title={readPreLubrication.name}
                                price={readPreLubrication.price}
                                originalPrice={readPreLubrication.original_price}
                                discount={readPreLubrication.discount}
                                img={readPreLubrication.img}
                                buyLink={readPreLubrication.buyLink}
                                details={readPreLubrication.details}
                                description={readPreLubrication.description}
                            />
                        )}
                    </tbody>
                    <tbody className="component-row-builder">
                        {readPreStabilizers.name === "" ? (
                            <AddComponent componentType="Stabilizers" componentFilled={false} />
                        ) : (
                            <AddComponent
                                componentType="Stabilizers"
                                componentFilled={true}
                                title={readPreStabilizers.name}
                                price={readPreStabilizers.price}
                                originalPrice={readPreStabilizers.original_price}
                                discount={readPreStabilizers.discount}
                                img={readPreStabilizers.img}
                                buyLink={readPreStabilizers.buyLink}
                                details={readPreStabilizers.details}
                                description={readPreStabilizers.description}
                            />
                        )}
                    </tbody>
                    <tbody className="component-row-builder">
                        {readPreOrings.name === "" ? (
                            <AddComponent componentType="O-Rings" componentFilled={false} />
                        ) : (
                            <AddComponent
                                componentType="O-Rings"
                                componentFilled={true}
                                title={readPreOrings.name}
                                price={readPreOrings.price}
                                originalPrice={readPreOrings.original_price}
                                discount={readPreOrings.discount}
                                img={readPreOrings.img}
                                buyLink={readPreOrings.buyLink}
                                details={readPreOrings.details}
                                description={readPreOrings.description}
                            />
                        )}
                    </tbody>
                    <tbody className="component-row-builder">
                        {readPreSwitchFilms.name === "" ? (
                            <AddComponent componentType="Switch Films" componentFilled={false} />
                        ) : (
                            <AddComponent
                                componentType="Switch Films"
                                componentFilled={true}
                                title={readPreSwitchFilms.name}
                                price={readPreSwitchFilms.price}
                                originalPrice={readPreSwitchFilms.original_price}
                                discount={readPreSwitchFilms.discount}
                                img={readPreSwitchFilms.img}
                                buyLink={readPreSwitchFilms.buyLink}
                                details={readPreSwitchFilms.details}
                                description={readPreSwitchFilms.description}
                            />
                        )}
                    </tbody>
                    <tbody className="component-row-builder">
                        {readPreCable.name === "" ? (
                            <AddComponent componentType="Cable" componentFilled={false} />
                        ) : (
                            <AddComponent
                                componentType="Cable"
                                componentFilled={true}
                                title={readPreCable.name}
                                price={readPreCable.price}
                                originalPrice={readPreCable.original_price}
                                discount={readPreCable.discount}
                                img={readPreCable.img}
                                buyLink={readPreCable.buyLink}
                                details={readPreCable.details}
                                description={readPreCable.description}
                            />
                        )}
                    </tbody>
                    <tbody className="component-row-builder">
                        {readPreDeskmats.name === "" ? (
                            <AddComponent componentType="Deskmats" componentFilled={false} />
                        ) : (
                            <AddComponent
                                componentType="Deskmats"
                                componentFilled={true}
                                title={readPreDeskmats.name}
                                price={readPreDeskmats.price}
                                originalPrice={readPreDeskmats.original_price}
                                discount={readPreDeskmats.discount}
                                img={readPreDeskmats.img}
                                buyLink={readPreDeskmats.buyLink}
                                details={readPreDeskmats.details}
                                description={readPreDeskmats.description}
                            />
                        )}
                    </tbody>
                    <tbody className="component-row-builder">
                        {readPreWristRests.name === "" ? (
                            <AddComponent componentType="Wrist rests" componentFilled={false} />
                        ) : (
                            <AddComponent
                                componentType="Wrist rests"
                                componentFilled={true}
                                title={readPreWristRests.name}
                                price={readPreWristRests.price}
                                originalPrice={readPreWristRests.original_price}
                                discount={readPreWristRests.discount}
                                img={readPreWristRests.img}
                                buyLink={readPreWristRests.buyLink}
                                details={readPreWristRests.details}
                                description={readPreWristRests.description}
                            />
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default PreBuiltBuilder;