import React from "react";
import './footer.css'

import Logo from '../../assets/logo';
import {FacebookOutlined, InstagramOutlined, TwitterOutlined} from '@ant-design/icons'


function Footer() {

    const socialStyle = {
        fontSize: '22px'
    }

    const currentYear = new Date().getFullYear();

    return (
        <>
            <footer>
                <div className='container'>
                    <div className='footer-column'>
                        <Logo />
                        <div className='socials'>
                            <a href="https://www.instagram.com/davinkeysbuilder/" target="_blank"><InstagramOutlined style={socialStyle} /></a>
                            <a href="https://www.facebook.com/DaVinkeys-100930862769475/" target="_blank"><FacebookOutlined style={socialStyle} /></a>
                            <a href="#" target="_blank"><TwitterOutlined style={socialStyle} /></a>
                        </div>
                    </div>
                    <div className='footer-column'>
                        <h5>Categories</h5>
                        <ol>
                            <li>Builder</li>
                            <li>Guides</li>
                            <li>Browse Products</li>
                        </ol>
                    </div>
                    <div className='footer-column'>
                        <h5>Company</h5>
                        <ol>
                            <li>About DaVinkeys</li>
                            <li>Contact</li>
                            <li>Industry Code of Conduct</li>
                            <li>Privacy Policy</li>
                            <li>Affiliate Disclosure</li>
                        </ol>
                    </div>
                </div>
                <div className='rights'>
                    <p>©{currentYear} DaVinkeys, LLC. All rights reserved.</p>
                </div>
            </footer>
            <div className="spacer"></div>
        </>
    );
}


export default Footer;