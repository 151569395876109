import {atom, selector} from "recoil";
import {recoilPersist} from "recoil-persist"


// recoil persist setup

const { persistAtom } = recoilPersist({
    key: 'persist-pre',
    storage: localStorage,
})


// pre-built atoms

export const prePreBuiltAtom = atom({
    key: "prePreBuiltState",
    default: {
        name: '',
        price: 0,
        original_price: 0,
        discount: '',
        img: '',
        buyLink: '',
        category: '',
        latest_volume: '',
        details: {
            Size: '',
            Notes: '',
        },
        description: 'No text available',
    },
    effects_UNSTABLE: [persistAtom],
});

export const preKeycapsAtom = atom({
    key: "preKeycapsState",
    default: {
        name: '',
        price: 0,
        original_price: 0,
        discount: '',
        img: '',
        buyLink: '',
        category: '',
        latest_volume: '',
        details: {
            Size: '',
            Notes: '',
        },
        description: 'No text available',
    },
    effects_UNSTABLE: [persistAtom],
});

export const preLubricationAtom = atom({
    key: "preLubricationState",
    default: {
        name: '',
        price: 0,
        original_price: 0,
        discount: '',
        img: '',
        buyLink: '',
        category: '',
        latest_volume: '',
        details: {
            Size: '',
            Notes: '',
        },
        description: 'No text available',
    },
    effects_UNSTABLE: [persistAtom],
});

export const preStabilizersAtom = atom({
    key: "preStabilizersState",
    default: {
        name: '',
        price: 0,
        original_price: 0,
        discount: '',
        img: '',
        buyLink: '',
        category: '',
        latest_volume: '',
        details: {
            Size: '',
            Notes: '',
        },
        description: 'No text available',
    },
    effects_UNSTABLE: [persistAtom],
});

export const preOringsAtom = atom({
    key: "preOringsState",
    default: {
        name: '',
        price: 0,
        original_price: 0,
        discount: '',
        img: '',
        buyLink: '',
        category: '',
        latest_volume: '',
        details: {
            Size: '',
            Notes: '',
        },
        description: 'No text available',
    },
    effects_UNSTABLE: [persistAtom],
});

export const preSwitchFilmsAtom = atom({
    key: "prewitchFilmsState",
    default: {
        name: '',
        price: 0,
        original_price: 0,
        discount: '',
        img: '',
        buyLink: '',
        category: '',
        latest_volume: '',
        details: {
            Size: '',
            Notes: '',
        },
        description: 'No text available',
    },
    effects_UNSTABLE: [persistAtom],
});


// accesoires 

export const preCableAtom = atom({
    key: "preCableState",
    default: {
        name: "",
        price: 0,
        original_price: 0,
        discount: "",
        img: "",
        buyLink: "",
        category: "",
        latest_volume: 0,
        details: {
            Size: "",
            Notes: "",
        },
        description: "No text available",
    },
    effects_UNSTABLE: [persistAtom],
});

export const preDeskmatsAtom = atom({
    key: "preDeskmatsState",
    default: {
        name: "",
        price: 0,
        original_price: 0,
        discount: "",
        img: "",
        buyLink: "",
        category: "",
        latest_volume: 0,
        details: {
            Size: "",
            Notes: "",
        },
        description: "No text available",
    },
    effects_UNSTABLE: [persistAtom],
});

export const preWristRestsAtom = atom({
    key: "preWristRestsState",
    default: {
        name: "",
        price: 0,
        original_price: 0,
        discount: "",
        img: "",
        buyLink: "",
        category: "",
        latest_volume: 0,
        details: {
            Size: "",
            Notes: "",
        },
        description: "No text available",
    },
    effects_UNSTABLE: [persistAtom],
});


export const pricePreTotalAtom = selector({
    key: 'pre-price-total',
    get: ({ get }) => {

        const preBuiltAtom = get(prePreBuiltAtom)
        // const switchAtom = get(customSwitchesAtom)
        const keycapsAtom = get(preKeycapsAtom)
        const lubricationAtom = get(preLubricationAtom)
        const stabilizersAtom = get(preStabilizersAtom)
        const oRingsAtom = get(preOringsAtom)
        const switchFilmsAtom = get(preSwitchFilmsAtom)
        const cableAtom = get(preCableAtom)
        const deskmatsAtom = get(preDeskmatsAtom)
        const wristRestsAtom = get(preWristRestsAtom)


        const preBuiltTotal = parseFloat(preBuiltAtom.price)
        const keycapsTotal = parseFloat(keycapsAtom.price)
        const lubricationTotal = parseFloat(lubricationAtom.price)
        const stabilizersTotal = parseFloat(stabilizersAtom.price)
        const oRingsTotal = parseFloat(oRingsAtom.price)
        const switchFilmsTotal = parseFloat(switchFilmsAtom.price)
        const cableTotal = parseFloat(cableAtom.price)
        const deskmatsTotal = parseFloat(deskmatsAtom.price)
        const wristRestsTotal = parseFloat(wristRestsAtom.price)

        const total = [preBuiltTotal, keycapsTotal, lubricationTotal, stabilizersTotal, oRingsTotal, switchFilmsTotal, cableTotal, deskmatsTotal, wristRestsTotal].reduce((result, number) => result + number)

        return total
    }
})