import React, {useState} from "react";
import "../App.css";
import Logo from "../assets/logo";

import NotFound from "../pages/states/notFoundPage";
import Home from "../pages/home/home";
import BuilderPage from "../pages/builder/builderPage";
import GuidePage from "../pages/guide/guidePage";
import ProductPage from "../pages/productsPage";
import PostItems from "../pages/postItems/postProductsPage";
import LoginForm from "../pages/authsystem/login";
import SignInForm from "../pages/authsystem/signIn";
import MyProfile from "../pages/profile/myProfile";

import {useAuth} from "../contexts/AuthContext";
import PrivateRoute from "../privateRoute";
import AdminRoute from "../adminRoute";

import {BrowserRouter as Router, Link, Route, Routes} from "react-router-dom";

import Icon, {
    AppstoreFilled,
    BookFilled,
    CloudUploadOutlined,
    LogoutOutlined,
    QuestionCircleFilled,
    ToolFilled,
    UserOutlined,
} from "@ant-design/icons";
import {Alert, Avatar, Button, Divider, Drawer, Dropdown, Menu, message, Modal, Popover,} from "antd";
import DropdownIcon from "../assets/icons/DropdownIcon";

// import { modalDetailPage } from "../recoil/atoms/modalContent";

function Nav(props) {
  const [isDisplayed, setDisplayed] = useState(false);
  const [rotation, setRotation] = useState(false);

  const showTheMenu = () => {
    setDisplayed(!isDisplayed);
    if (rotation === false) {
      setRotation(true);
    } else if (rotation === true) {
      setRotation(false);
    }
  };

  const closeTheMenu = () => {
    setDisplayed(false);
    setRotation(false);
  };

  const { credentials } = useAuth();

  // logout

  function logout() {
    localStorage.clear();
    window.location.reload();
  }

  // modal logic

  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);

  function showLoginModal() {
    setIsLoginModalVisible(true);
  }

  function handleLoginCancel() {
    setIsLoginModalVisible(false);
  }

  const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false);

  function showRegisterModal() {
    setIsRegisterModalVisible(true);
  }

  function handleRegisterCancel() {
    setIsRegisterModalVisible(false);
  }

  // login logic

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (evt) => {
    setEmail(evt.target.value);
  };

  const handlePasswordChange = (evt) => {
    setPassword(evt.target.value);
  };

  async function handleSubmitLogin(evt) {
    evt.preventDefault();

    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_LINK}/v1/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + process.env.REACT_APP_LOGIN_TOKEN,
          },
          body: JSON.stringify({
            email,
            password,
          }),
        }
      );
      const json = await response.json();
      if (json.user === undefined) {
        throw json;
      }
      localStorage.setItem("token", json.jwt);
      localStorage.setItem("userObj", JSON.stringify(json));
      console.log(json);
      setIsLoginModalVisible(false);
      window.location.reload();
      message.success(json.success);
    } catch (error) {
      if (error.errors === undefined) {
        console.log("Something went wrong, please try later");
        setErrorMessageLogin("Something went wrong, please try later");
      } else {
        setErrorMessageLogin(error.errors);
      }
      localStorage.clear();
    }
    setEmail("");
    setPassword("");
    setLoading(false);
  }

  // register logic

  const [name, setName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleNameChange = (evt) => {
    setName(evt.target.value);
  };

  const handleConfirmPasswordChange = (evt) => {
    setConfirmPassword(evt.target.value);
  };

  async function handleSubmitRegister(evt) {
    evt.preventDefault();

    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_LINK}/v1/users`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + process.env.REACT_APP_LOGIN_TOKEN,
          },
          body: JSON.stringify({
            user: {
              name,
              email,
              password,
              confirmPassword,
            },
          }),
        }
      );
      const json = await response.json();
      if (json.user === undefined) {
        throw json;
      }
      localStorage.setItem("token", json.jwt);
      localStorage.setItem("userObj", JSON.stringify(json));
      console.log(json);
      setIsRegisterModalVisible(false);
      message.success(json.success);
      window.location.reload();
    } catch (error) {
      if (error.errors === undefined) {
        console.log("Something went wrong, please try later");
        setErrorMessageRegister("Something went wrong, please try later");
      } else {
        setErrorMessageRegister(
          error.errors.map((message) => (
            <span key={message}>
              {message} <br />
            </span>
          ))
        );
      }
      localStorage.clear();
    }
    setName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setLoading(false);
  }

  // error handling in the FE

  const [errorMessageLogin, setErrorMessageLogin] = useState(false);
  const [errorMessageRegister, setErrorMessageRegister] = useState(false);

  // custom stylings

  const userDropdownStyle = {
    fontWeight: "bold",
    fontSize: "18px",
    margin: "12px",
  };

  const avatarHoverStyle = {
    cursor: "pointer",
  };

  const dividerstyle = {
    border: "1px solid #3D3D3D",
    height: "32px",
  };

  const signinStyle = {
    color: "#fff",
  };

  // mobile show drawer Menu

  const [drawerVisible, setDrawerVisible] = useState(false);

  function showDrawer() {
    setDrawerVisible(true);
  }

  function closeDrawer() {
    setDrawerVisible(false);
  }

  // products menu logic for dissappear on click

  return (
    <Router>
      <div className="header-nav">
        <nav>
          <div>
            <Link to="/" onClick={closeTheMenu}>
              <Logo />
            </Link>
            <ul className="nav-items">
              {!credentials && (
                <li className="d-none">
                  <Divider type="vertical" style={dividerstyle}></Divider>
                  <Button
                    type="link"
                    className="d-none"
                    onClick={showLoginModal}
                    style={signinStyle}
                  >
                    Log In
                  </Button>
                  <Button
                    type="link"
                    onClick={showRegisterModal}
                    style={signinStyle}
                  >
                    Sign Up
                  </Button>
                </li>
              )}
              {credentials && (
                <li>
                  <Divider type="vertical" style={dividerstyle}></Divider>
                  <Dropdown
                    arrow="true"
                    placement="bottomRight"
                    trigger={["click"]}
                    overlay={
                      <Menu>
                        <Menu.Item key="showName">
                          <div style={userDropdownStyle}>
                            {`Hello, ${credentials.name}`}
                          </div>
                        </Menu.Item>
                        <Menu.Item key="goProfile" icon={<UserOutlined />}>
                          <Link to="/my-profile">My Profile</Link>
                        </Menu.Item>
                        {credentials.role === "admin" && (
                          <Menu.Item
                            key="adminPage"
                            icon={<CloudUploadOutlined />}
                          >
                            <Link to="/post-items">Product Uploader</Link>
                          </Menu.Item>
                        )}
                        <Menu.Item
                          key="logOut"
                          danger
                          icon={<LogoutOutlined />}
                          onClick={logout}
                        >
                          Log out
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Avatar
                      onClick={closeTheMenu}
                      icon={<UserOutlined />}
                      style={avatarHoverStyle}
                    />
                  </Dropdown>
                </li>
              )}
              <li onClick={closeTheMenu}>
                <Link to="/builder">
                  <Button
                    type="primary"
                    className="default-nav-buttons builder-button"
                    shape="round"
                    value="large"
                    icon={<ToolFilled />}
                  >
                    Builder
                  </Button>
                </Link>
              </li>
              {/* <li onClick={closeTheMenu}>
                <Link to="/guides">
                  <Button
                    className="default-nav-buttons"
                    type="default"
                    shape="round"
                    value="large"
                    icon={<BookFilled />}
                  >
                    Guides
                  </Button>
                </Link>
              </li> */}
              {/* <li className="nav-item-products" onClick={showTheMenu}>
                <Dropdown
                  onVisibleChange={showTheMenu}
                  arrow="true"
                  placement="topCenter"
                  trigger={["click"]}
                  overlay={
                    <Menu className="nav-item-products-dropdown">
                      <Menu.ItemGroup
                        title="Browse pre-builds"
                        className="nav-item-products-dropdown-group"
                      >
                        <Menu.Item key="g1i1">
                          <Link to="/">
                            <div className="nav-browse-styles nav-browse-1">
                              <h5>Pre-builds</h5>
                            </div>
                          </Link>
                        </Menu.Item>
                      </Menu.ItemGroup>
                      <Menu.ItemGroup
                        title="Browse custom build components"
                        className="nav-item-products-dropdown-group"
                      >
                        <Menu.Item key="g2i1">
                          <Link to="/">
                            <div className="nav-browse-styles nav-browse-2">
                              <h5>Boards</h5>
                            </div>
                          </Link>
                        </Menu.Item>
                        <Menu.Item key="g2i2">
                          <Link to="/">
                            <div className="nav-browse-styles nav-browse-3">
                              <h5>Switches</h5>
                            </div>
                          </Link>
                        </Menu.Item>
                        <Menu.Item key="g2i3">
                          <Link to="/">
                            <div className="nav-browse-styles nav-browse-4">
                              <h5>Keycaps</h5>
                            </div>
                          </Link>
                        </Menu.Item>
                      </Menu.ItemGroup>
                      <Menu.Divider></Menu.Divider>
                      <Menu.ItemGroup title="Mods">
                        <Menu.Item>
                          <Link to="/">Lubricants</Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link to="/">Stabilizers</Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link to="/">O-Rings</Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link to="/">PCB Plates</Link>
                        </Menu.Item>
                      </Menu.ItemGroup>
                      <Menu.ItemGroup title="Accessoires">
                        <Menu.Item>
                          <Link to="/">Custom Cable</Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link to="/">Lube kit</Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link to="/">Keycap pullers</Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link to="/">Switch Films</Link>
                        </Menu.Item>
                      </Menu.ItemGroup>
                    </Menu>
                  }
                >
                  <Button
                    className="products-menu default-nav-buttons"
                    type="default"
                    shape="round"
                    value="large"
                    icon={
                      <Icon
                        component={DropdownIcon}
                        className={
                          rotation ? "dropdown-icon open" : "dropdown-icon"
                        }
                      />
                    }
                  >
                    Browse products
                  </Button>
                </Dropdown>
              </li> */}
              <li className="mobile-nav-item d-none">
                <Dropdown
                  arrow="true"
                  placement="bottomRight"
                  trigger={["click"]}
                  overlay={
                    <Menu>
                      <Menu.Item key="login" onClick={showLoginModal}>
                        Log In
                      </Menu.Item>
                      <Menu.Item key="signup" onClick={showRegisterModal}>
                        Sign Up
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button
                    type="primary"
                    shape="round"
                    value="small"
                    icon={<Icon component={DropdownIcon} />}
                  >
                    Sign In
                  </Button>
                </Dropdown>
              </li>
              <li className="mobile-nav-item">
                <Button type="link" onClick={showDrawer}>
                  Menu
                </Button>
              </li>
            </ul>
          </div>
        </nav>
        <Modal
          title="Log In"
          open={isLoginModalVisible}
          okText="Log In"
          onOk={handleSubmitLogin}
          confirmLoading={loading}
          onCancel={handleLoginCancel}
        >
          <form onSubmit={handleSubmitLogin}>
            {errorMessageLogin && (
              <Alert message={errorMessageLogin} type="error" />
            )}
            <div>
              <label>Email</label>
              <input
                className="ant-input"
                value={email}
                onChange={handleEmailChange}
                type="email"
                placeholder="email"
              />
            </div>
            <div>
              <label>Password</label>
              <input
                className="ant-input"
                value={password}
                onChange={handlePasswordChange}
                type="password"
                placeholder="password"
              />
            </div>
            <Link
              to="/dfdfd"
              style={{
                margin: "8px 0 0 0",
                display: "block",
              }}
              // onClick={Modal.info().destroy()}
            >
              Forgot my password
            </Link>
            <Divider />
            <p>
              Don't have an account yet? Sign up{" "}
              <Link to="/signup" onClick={handleLoginCancel}>
                here
              </Link>
            </p>
          </form>
        </Modal>
        <Modal
          title="Register"
          open={isRegisterModalVisible}
          okText="Register"
          onOk={handleSubmitRegister}
          confirmLoading={loading}
          onCancel={handleRegisterCancel}
        >
          <form onSubmit={handleSubmitRegister}>
            {errorMessageRegister && (
              <Alert message={errorMessageRegister} type="error" />
            )}
            <div className="field">
              <label>Name*</label>
              <input
                className="ant-input"
                value={name}
                onChange={handleNameChange}
                type="text"
                placeholder="name"
              />
            </div>
            <div className="field">
              <label>Email*</label>
              <input
                className="ant-input"
                value={email}
                onChange={handleEmailChange}
                type="email"
                placeholder="email"
              />
            </div>
            <div className="field">
              <Popover
                content={
                  <div className="password-requirements">
                    <h6>Password requirements</h6>
                    <ul>
                      <li>Password must have at least 8 characters</li>
                      <li>
                        Password must contain at least one uppercase letter
                      </li>
                      <li>Password must contain at least one digit</li>
                    </ul>
                  </div>
                }
              >
                <label>
                  Password* <QuestionCircleFilled />
                </label>
              </Popover>
              <input
                className="ant-input"
                value={password}
                onChange={handlePasswordChange}
                type="password"
                placeholder="password"
              />
            </div>
            <div className="field">
              <label>Confirm Password*</label>
              <input
                className="ant-input"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                type="password"
                placeholder="confirm password"
              />
            </div>
            <Divider />
            <p>
              Already have an account? Log in{" "}
              <Link to="/login" onClick={handleRegisterCancel}>
                here
              </Link>
            </p>
          </form>
        </Modal>
        <Drawer
          title="Menu Davinkeys"
          width={320}
          open={drawerVisible}
          onClose={closeDrawer}
        >
          <Menu defaultSelectedKeys={["1"]} mode="inline">
            <Menu.SubMenu
              key="sub1"
              icon={<AppstoreFilled />}
              title="Browse products"
            >
              <Menu.Item key="g1">Browse Pre-Builds</Menu.Item>
              <Menu.SubMenu key="g2" title="Browse Custom Build Components">
                <Menu.Item key="g2i1">Boards & PCB</Menu.Item>
                <Menu.Item key="g2i2">Switches</Menu.Item>
                <Menu.Item key="g2i3">Keycaps</Menu.Item>
              </Menu.SubMenu>
              <Menu.SubMenu title="Other components">
                <Menu.ItemGroup key="g3" title="Mods">
                  <Menu.Item key="g3i1">Lubricants</Menu.Item>
                  <Menu.Item key="g3i2">Stabilizers</Menu.Item>
                  <Menu.Item key="g3i3">O-Rings</Menu.Item>
                  <Menu.Item key="g3i4">PCB Plates</Menu.Item>
                </Menu.ItemGroup>
                <Menu.ItemGroup key="g4" title="Accessoires">
                  <Menu.Item key="g4i1">Custom Cable</Menu.Item>
                  <Menu.Item key="g4i2">Lube kit</Menu.Item>
                  <Menu.Item key="g4i3">Keycap pullers</Menu.Item>
                  <Menu.Item key="g4i4">Switch Films</Menu.Item>
                </Menu.ItemGroup>
              </Menu.SubMenu>
            </Menu.SubMenu>
            <Menu.Item className="icon-left" icon={<BookFilled />}>
              Guides
            </Menu.Item>
            <Menu.Item className="icon-left" icon={<ToolFilled />}>
              Builder
            </Menu.Item>
          </Menu>
        </Drawer>
      </div>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/builder" element={<BuilderPage />}></Route>
        <Route path="/guides" element={<GuidePage />}></Route>
        <Route path="/products" element={<ProductPage />}></Route>
        <Route path="/post-items" element={
          <AdminRoute>
            <PostItems />
          </AdminRoute>
        }></Route>
        <Route path="/login" element={<LoginForm />}></Route>
        <Route path="/signup" element={<SignInForm />}></Route>
        <Route path="/my-profile" element={
          <PrivateRoute>
            <MyProfile />
          </PrivateRoute>
        }></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </Router>
  );
}

export default Nav;
