import {atom, selector} from "recoil";
import {recoilPersist} from "recoil-persist";

const { persistAtom } = recoilPersist({
  key: "persist-info",
  storage: localStorage,
});

export const modalDetailPage = atom({
    key: "single-component-detail",
    default: '',
});

export const modalDetailPageInfo = selector({
    key: "single-component-info",
    get: ({ get }) => {
        const nameComponent = get(modalDetailPage);
        const componentObject = {
            name: nameComponent,
            totalPrice: 55.65,
            discount: 4,
            orders: 1200,
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In urna quam, ornare at velit a, tempor venenatis augue. Sed quis erat massa. Phasellus ex nisi, cursus a enim eu, eleifend commodo lacus. Mauris eu urna laoreet, pharetra odio ac, maximus tellus. In sit amet nisi lectus. Sed in rutrum nulla. Vivamus ut accumsan tellus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Praesent pretium nunc non semper facilisis.',
            rating: 3,
            ratersAmount: 15,
            specifications: {
                category: 'Board',
                brand: 'Holy Faker Pandas',
                keyboardSize: '50%',
                colors: [
                    'Green',
                    'Blue',
                ],
                switches: [
                    'Gateron',
                    'Cherry',
                ],
                led: false,
                layout: 'ANSI',
            },
        }

        return componentObject
    }
});

export const learnMoreInfo = atom({
  key: "learn-more-component",
  default: {
    info: {
      
      },
    },
  },
);

