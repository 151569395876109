import React from 'react'

import './CommunityCardStyles.css'

export default function CommunityCard(props) {

    const communityStyle = {
        background: `url(${props.imageLink})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    }

    return (
        <div className='community-card'>
            <div
                className='community-build-image'
                style={communityStyle}>
            </div>
            <div className='community-card-content'>
                <h3>{props.buildName}</h3>
                <span>Made by {props.author}</span>
                <p>{props.bodyText}</p>
            </div>
        </div>
    )
}
