import React from "react";
// import '../App.css';
import "../../styles/pageTemplatesStyles.css";
import "./homeStyles.css";
import Builds from "../../components/homeComponents/buildyourown/Builds";
import CommunityCard from "../../components/homeComponents/bythecommunity/CommunityCard";
import ProcessCard from "../../components/homeComponents/processCard/processCard";
import ChevronRight from "../../assets/icons/ChevronRight";

import {Button, Carousel} from "antd";

import preExampleImage from "../../assets/images/PreExample.png";
import customExampleImage from "../../assets/images/CustomExample.png";
import buildCtaExampleImage from "../../assets/images/Build_CTA.png";

import howBoard from "../../assets/images/How_Board.png";
import howCaps from "../../assets/images/How_Caps.png";
import howSwitch from "../../assets/images/How_Switch.png";

function Home() {
  return (
    <div className="wrapper home-container">
      <section className="home-section-1">
        <div className="fold-section">
          <h1>Building Keyboards Made Easy</h1>
          <p>
            Interested in building a keyboard that you can call yours? Let us
            help you get started with finding your own custom keyboard whether
            that be a pre-built one or a custom made one with our builder!
          </p>
        </div>
        {/* <div className='build-section'> */}
        <Builds
          title="Pre-built"
          span="Casual"
          bodyText="Pre-built keyboards with optional customization suitable for every user."
          imageLink={preExampleImage}
          builderInitialId="pre"
          buttonText="Create pre-built board"
        />
        <Builds
          title="Custom Builds"
          span="Intermediate"
          bodyText="Custom build keyboards with unlimited customization for intermediate users."
          imageLink={customExampleImage}
          builderInitialId="custom"
          buttonText="Create custom board"
        />
        {/* </div> */}
      </section>
      <section className="home-section-2">
        <div>
          <h2>How does it work?</h2>
          <div className="process-section">
            <ProcessCard
              title="Choose your board"
              step="Step 1"
              img={howBoard}
            ></ProcessCard>
            <ProcessCard
              title="Select your desired switches"
              step="Step 2"
              img={howSwitch}
            ></ProcessCard>
            <ProcessCard
              title="Customize with keycaps"
              step="Step 3"
              img={howCaps}
            ></ProcessCard>
          </div>
          {/* <h2>Learn more about the parts</h2>
          <div className="guide-section">
            <GuideCard
              steps="Guide"
              title="Boards"
              bodyText="The body of the keyboard. Explore different options known in the keyboard industry."
              imageLink={boardExampleImage}
              icon={<ChevronRight />}
            />
            <GuideCard
              steps="Guide"
              title="Switches"
              bodyText="Choosing a switch type comes all down to preference. Ranging from tactile to linear, explore the whole anatomy of switches."
              imageLink={switchExampleImage}
            />
            <GuideCard
              steps="Guide"
              title="Keycaps"
              bodyText="The most visual component of a keyboard. Explore the different types of keycaps and materials used."
              imageLink={keycapExampleImage}
            />
          </div> */}
        </div>
      </section>
      <section className="home-section-3">
        <h2>Made by the community</h2>
        <div className="community-section">
          <Carousel autoplay="true">
            <CommunityCard
              buildName="Crazy Ashe inspired build"
              imageLink="https://media-exp1.licdn.com/dms/image/C4D03AQH4N5LFgVh6-w/profile-displayphoto-shrink_200_200/0/1571671496185?e=1616630400&v=beta&t=gIZfOSixnaKwmbpKrfAdazvbC6jtWP2iG4Z0uNklOrc"
              author="Irfan"
              bodyText="lorem"
            />
            <CommunityCard
              buildName="GMK Cafe inspired build"
              imageLink="https://media-exp1.licdn.com/dms/image/C4D03AQH4N5LFgVh6-w/profile-displayphoto-shrink_200_200/0/1571671496185?e=1616630400&v=beta&t=gIZfOSixnaKwmbpKrfAdazvbC6jtWP2iG4Z0uNklOrc"
              author="Patrick Spankavet"
              bodyText="lorem"
            />
            <CommunityCard
              buildName="Crazy Ashe inspired build"
              imageLink="https://media-exp1.licdn.com/dms/image/C4D03AQH4N5LFgVh6-w/profile-displayphoto-shrink_200_200/0/1571671496185?e=1616630400&v=beta&t=gIZfOSixnaKwmbpKrfAdazvbC6jtWP2iG4Z0uNklOrc"
              author="Irfan"
              bodyText="lorem"
            />
            <CommunityCard
              buildName="GMK Cafe inspired build"
              imageLink="https://media-exp1.licdn.com/dms/image/C4D03AQH4N5LFgVh6-w/profile-displayphoto-shrink_200_200/0/1571671496185?e=1616630400&v=beta&t=gIZfOSixnaKwmbpKrfAdazvbC6jtWP2iG4Z0uNklOrc"
              author="Patrick Spankavet"
              bodyText="lorem"
            />
          </Carousel>
        </div>
      </section>
      <section className="home-section-4">
        <div>
          <div className="CTA_two">
            <div>
              <h2>Want to try the builder?</h2>
              <p>
                Try the builder out for yourself! Share you builds with fellow
                keyboards enthusiasts
              </p>
              <a href="/builder" >
                <Button type="primary" shape="round" value="large" className="CTA_button">
                  Start building
                  <ChevronRight />
                </Button>
              </a>
            </div>
            <div className="highlighted-build">
              <img src={buildCtaExampleImage} alt="highlight-builder"></img>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Home;
