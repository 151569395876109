import React from "react";
import '../../App.css';
import './builderTypeStyles.css'
import AddComponent from './components/add-component/AddComponent'

import {useRecoilValue} from 'recoil'
import {
  customBoardAtom,
  customCableAtom,
  customDeskmatsAtom,
  customKeycapPullerAtom,
  customKeycapsAtom,
  customLubricationAtom,
  customOringsAtom,
  customStabilizersAtom,
  customSwitchesAtom,
  customSwitchFilmsAtom,
  customSwitchHolderAtom,
  customSwitchOpenerAtom,
  customSwitchTesterAtom,
  customWristRestsAtom
} from '../../recoil/atoms/builder'


function CustomBuilder() {

  const readCustomBoard = useRecoilValue(customBoardAtom)
  const readCustomSwitches = useRecoilValue(customSwitchesAtom)
  const readCustomKeycaps = useRecoilValue(customKeycapsAtom)
  const readCustomLubrication = useRecoilValue(customLubricationAtom)
  const readCustomStabilizers = useRecoilValue(customStabilizersAtom)
  const readCustomOrings = useRecoilValue(customOringsAtom)
  const readCustomSwitchFilms = useRecoilValue(customSwitchFilmsAtom)
  const readCustomCable = useRecoilValue(customCableAtom)
  const readCustomSwitchTester = useRecoilValue(customSwitchTesterAtom)
  const readCustomKeycapPuller = useRecoilValue(customKeycapPullerAtom)
  const readCustomSwitchOpener = useRecoilValue(customSwitchOpenerAtom)
  const readCustomSwitchHolder = useRecoilValue(customSwitchHolderAtom)
  const readCustomDeskmats = useRecoilValue(customDeskmatsAtom)
  const readCustomWristRests = useRecoilValue(customWristRestsAtom)


  return (
    <div className="builder-type">
      <div className="builder-type-container main-components">
        <table
          style={{
            width: "100%",
            borderSpacing: 0,
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  paddingLeft: "24px",
                  maxWidth: "60px",
                }}
              >
                Main Components
              </th>
              <th></th>
              <th></th>
              {readCustomBoard.name === "" &&
                readCustomSwitches.name === "" &&
                readCustomKeycaps.name === "" ? (
                <>
                  <th></th>
                  <th></th>
                  <th></th>
                </>
              ) : (
                <>
                  <th>Price</th>
                  <th>Discount</th>
                  <th>Total</th>
                </>
              )}
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody className="component-row-builder">
            {readCustomBoard.name === "" ? (
              <AddComponent componentType="Board" componentFilled={false} />
            ) : (
              <AddComponent
                componentType="Board"
                componentFilled={true}
                title={readCustomBoard.name}
                price={readCustomBoard.price}
                originalPrice={readCustomBoard.original_price}
                discount={readCustomBoard.discount}
                img={readCustomBoard.img}
                buyLink={readCustomBoard.buyLink}
              // details={readCustomBoard.details}
              // description={readCustomSwitches.description}
              />
            )}
          </tbody>
          <tbody className="component-row-builder">
            {readCustomSwitches.name === "" ? (
              <AddComponent
                componentType="Switch"
                componentFilled={false}
              />
            ) : (
              <AddComponent
                componentType="Switch"
                componentFilled={true}
                title={readCustomSwitches.name}
                price={readCustomSwitches.price}
                originalPrice={readCustomSwitches.original_price}
                discount={readCustomSwitches.discount}
                img={readCustomSwitches.img}
                buyLink={readCustomSwitches.buyLink}
              // details={readCustomSwitches.details}
              // description={readCustomSwitches.description}
              />
            )}
          </tbody>
          <tbody className="component-row-builder">
            {readCustomKeycaps.name === "" ? (
              <AddComponent
                componentType="Keycaps"
                componentFilled={false}
              />
            ) : (
              <AddComponent
                componentType="Keycaps"
                componentFilled={true}
                title={readCustomKeycaps.name}
                price={readCustomKeycaps.price}
                originalPrice={readCustomKeycaps.original_price}
                discount={readCustomKeycaps.discount}
                img={readCustomKeycaps.img}
                buyLink={readCustomKeycaps.buyLink}
              // details={readCustomKeycaps.details}
              // description={readCustomSwitches.description}
              />
            )}
          </tbody>
          <thead className="component-header-extra">
            <tr>
              <th
                style={{
                  paddingLeft: "24px",
                  maxWidth: "60px",
                }}
              >
                Modifications (optional)
              </th>
            </tr>
          </thead>
          <tbody className="component-row-builder">
            {readCustomLubrication.name === "" ? (
              <AddComponent componentType="Lubrication" componentFilled={false} />
            ) : (
              <AddComponent
                componentType="Lubrication"
                componentFilled={true}
                title={readCustomLubrication.name}
                price={readCustomLubrication.price}
                originalPrice={readCustomLubrication.original_price}
                discount={readCustomLubrication.discount}
                img={readCustomLubrication.img}
                buyLink={readCustomLubrication.buyLink}
              // details={readCustomLubrication.details}
              // description={readCustomLubrication.description}
              />
            )}
          </tbody>
          <tbody className="component-row-builder">
            {readCustomStabilizers.name === "" ? (
              <AddComponent componentType="Stabilizers" componentFilled={false} />
            ) : (
              <AddComponent
                componentType="Stabilizers"
                componentFilled={true}
                title={readCustomStabilizers.name}
                price={readCustomStabilizers.price}
                originalPrice={readCustomStabilizers.original_price}
                discount={readCustomStabilizers.discount}
                img={readCustomStabilizers.img}
                buyLink={readCustomStabilizers.buyLink}
              // details={readCustomStabilizers.details}
              // description={readCustomStabilizers.description}
              />
            )}
          </tbody>
          <tbody className="component-row-builder">
            {readCustomOrings.name === "" ? (
              <AddComponent componentType="O-Rings" componentFilled={false} />
            ) : (
              <AddComponent
                componentType="O-Rings"
                componentFilled={true}
                title={readCustomOrings.name}
                price={readCustomOrings.price}
                originalPrice={readCustomOrings.original_price}
                discount={readCustomOrings.discount}
                img={readCustomOrings.img}
                buyLink={readCustomOrings.buyLink}
              // details={readCustomOrings.details}
              // description={readCustomOrings.description}
              />
            )}
          </tbody>
          <tbody className="component-row-builder">
            {readCustomSwitchFilms.name === "" ? (
              <AddComponent componentType="Switch Films" componentFilled={false} />
            ) : (
              <AddComponent
                componentType="Switch Films"
                componentFilled={true}
                title={readCustomSwitchFilms.name}
                price={readCustomSwitchFilms.price}
                originalPrice={readCustomSwitchFilms.original_price}
                discount={readCustomSwitchFilms.discount}
                img={readCustomSwitchFilms.img}
                buyLink={readCustomSwitchFilms.buyLink}
              // details={readCustomSwitchFilms.details}
              // description={readCustomSwitchFilms.description}
              />
            )}
          </tbody>
          <thead className="component-header-extra">
            <th
              style={{
                paddingLeft: "24px",
                maxWidth: "60px",
              }}
            >
              Accessoires (optional)
            </th>
          </thead>
          <tbody className="component-row-builder">
            {readCustomCable.name === "" ? (
              <AddComponent componentType="Cable" componentFilled={false} />
            ) : (
              <AddComponent
                componentType="Cable"
                componentFilled={true}
                title={readCustomCable.name}
                price={readCustomCable.price}
                originalPrice={readCustomCable.original_price}
                discount={readCustomCable.discount}
                img={readCustomCable.img}
                buyLink={readCustomCable.buyLink}
              // details={readCustomCable.details}
              // description={readCustomCable.description}
              />
            )}
          </tbody>
          <tbody className="component-row-builder">
            {readCustomSwitchTester.name === "" ? (
              <AddComponent
                componentType="Switch Tester"
                componentFilled={false}
              />
            ) : (
              <AddComponent
                componentType="Switch Tester"
                componentFilled={true}
                title={readCustomSwitchTester.name}
                price={readCustomSwitchTester.price}
                originalPrice={readCustomSwitchTester.original_price}
                discount={readCustomSwitchTester.discount}
                img={readCustomSwitchTester.img}
                buyLink={readCustomSwitchTester.buyLink}
              // details={readCustomSwitchTester.details}
              // description={readCustomSwitchTester.description}
              />
            )}
          </tbody>
          <tbody className="component-row-builder">
            {readCustomKeycapPuller.name === "" ? (
              <AddComponent
                componentType="Keycap Pullers"
                componentFilled={false}
              />
            ) : (
              <AddComponent
                componentType="Keycap Pullers"
                componentFilled={true}
                title={readCustomKeycapPuller.name}
                price={readCustomKeycapPuller.price}
                originalPrice={readCustomKeycapPuller.original_price}
                discount={readCustomKeycapPuller.discount}
                img={readCustomKeycapPuller.img}
                buyLink={readCustomKeycapPuller.buyLink}
              // details={readCustomKeycapPuller.details}
              // description={readCustomKeycapPuller.description}
              />
            )}
          </tbody>
          <tbody className="component-row-builder">
            {readCustomSwitchOpener.name === "" ? (
              <AddComponent
                componentType="Switch Opener"
                componentFilled={false}
              />
            ) : (
              <AddComponent
                componentType="Switch Opener"
                componentFilled={true}
                title={readCustomSwitchOpener.name}
                price={readCustomSwitchOpener.price}
                originalPrice={readCustomSwitchOpener.original_price}
                discount={readCustomSwitchOpener.discount}
                img={readCustomSwitchOpener.img}
                buyLink={readCustomSwitchOpener.buyLink}
              // details={readCustomSwitchOpener.details}
              // description={readCustomSwitchOpener.description}
              />
            )}
          </tbody>
          <tbody className="component-row-builder">
            {readCustomSwitchHolder.name === "" ? (
              <AddComponent
                componentType="Switch Holder"
                componentFilled={false}
              />
            ) : (
              <AddComponent
                componentType="Switch Holder"
                componentFilled={true}
                title={readCustomSwitchHolder.name}
                price={readCustomSwitchHolder.price}
                originalPrice={readCustomSwitchHolder.original_price}
                discount={readCustomSwitchHolder.discount}
                img={readCustomSwitchHolder.img}
                buyLink={readCustomSwitchHolder.buyLink}
              // details={readCustomSwitchHolder.details}
              // description={readCustomSwitchHolder.description}
              />
            )}
          </tbody>
          <tbody className="component-row-builder">
            {readCustomDeskmats.name === "" ? (
              <AddComponent
                componentType="Deskmats"
                componentFilled={false}
              />
            ) : (
              <AddComponent
                componentType="Deskmats"
                componentFilled={true}
                title={readCustomDeskmats.name}
                price={readCustomDeskmats.price}
                originalPrice={readCustomDeskmats.original_price}
                discount={readCustomDeskmats.discount}
                img={readCustomDeskmats.img}
                buyLink={readCustomDeskmats.buyLink}
              // details={readCustomDeskmats.details}
              // description={readCustomDeskmats.description}
              />
            )}
          </tbody>
          <tbody className="component-row-builder">
            {readCustomWristRests.name === "" ? (
              <AddComponent
                componentType="Wrist rests"
                componentFilled={false}
              />
            ) : (
              <AddComponent
                componentType="Wrist rests"
                componentFilled={true}
                title={readCustomWristRests.name}
                price={readCustomWristRests.price}
                originalPrice={readCustomWristRests.original_price}
                discount={readCustomWristRests.discount}
                img={readCustomWristRests.img}
                buyLink={readCustomWristRests.buyLink}
              // details={readCustomWristRests.details}
              // description={readCustomWristRests.description}
              />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CustomBuilder;