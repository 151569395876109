import React from "react";
import '../../App.css';
import './myProfileStyles.css';
import '../../styles/pageTemplatesStyles.css'


function MyProfile() {

    return (
        <div className='wrapper template-padding'>
            <div className='wrapped-container'>
                <h1>My Profile</h1>
                <p>Learn more about</p>
            </div>
        </div>
    );
}

export default MyProfile;